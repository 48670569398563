export const LOGOS = [
  [
    'logo-sase.png',
    'logo-food.jpeg',
    'logo-winia.png',
    'logo-carrier.png',
    'logo-daesang.png',
    'logo-euro.png',
    'logo-cj-jedang.png',
    'logo-dongseo.png',
  ],
  [
    'logo-hd-glovis.png',
    'logo-lotte-logis.png',
    'logo-emart.png',
    'logo-dongwon.png',
    'logo-han.png',
    'logo-cj-logis.png',
    'logo-naver.jpg',
    'logo-fdsys.png',
  ],
]
